import { graphql, PageProps } from "gatsby";
import React from "react";
import "twin.macro";
import { LocationCard } from "../components/common";
import { SEO } from "../components";

const LocationPage: React.FC<PageProps> = ({ data: { strapiBranch } }) => {
  return (
    <>
      <SEO title={strapiBranch.locationName} location={strapiBranch} />
      <section aria-label={strapiBranch.locationName}>
        <div tw="max-w-screen-xl mx-auto lg:pt-12">
          <LocationCard branch={strapiBranch} expanded={true} />
        </div>
      </section>
    </>
  );
};

export default LocationPage;

export const query = graphql`
  query LocationPageTemplate($id: String!) {
    strapiBranch(id: { eq: $id }) {
      id
      slug
      address {
        addressCountry
        addressLocality
        addressRegion
        postalCode
        streetAddress
        override
      }
      booking_url
      cityOrCounty
      email
      googlePlacesId
      locationName
      openingHours {
        monday {
          closed
          closes
          opens
        }
        tuesday {
          closed
          closes
          opens
        }
        wednesday {
          closed
          closes
          opens
        }
        thursday {
          closed
          closes
          opens
        }
        friday {
          closed
          closes
          opens
        }
        saturday {
          closed
          closes
          opens
        }
        sunday {
          closed
          closes
          opens
        }
      }
      telephone
      image {
        url
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;
